<template>
  <div>
    <h1>Админ панель находится в разработке</h1>
    <v-btn color="success" @click="()=>$router.push('/admin/dashboard')"> Dashboard </v-btn>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>
